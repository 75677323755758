import { find } from '@execonline-inc/collections';
import { identity, noop } from '@kofno/piper';
import { Maybe } from 'maybeasy';
import { Task } from 'taskarian';
import { DynamicImportError } from '../DynamicImports/Types';
import { importCssVarsPonyfillModule } from '../DynamicImports/css-vars-ponyfill';
import { tailwindTheme } from '../Native/tailwind-theme';
import { CompanyTheme, DefaultTheme, Theme, ThemeApplicationError } from './Types';

// TODO - can this whole thing go away???
export const defaultTheme: DefaultTheme = {
  variables: {
    'body-bg': tailwindTheme.colors.neutral['800'], //'#2b2b2b',
    'body-bg-darker': tailwindTheme.colors.zinc['800'], //#27272a, was #212121',
    'body-bg-darkest': tailwindTheme.colors.zinc['900'], //#18181b
    'body-separator': tailwindTheme.colors.zinc['500'], //#18181b
    'body-text': tailwindTheme.colors.gray[200], //'#eee',
    'static-page-bg-image': 'url("/p3/login-bg.jpg")',
    'btn-bg': tailwindTheme.colors.zinc[600], // '#555',
    'btn-bg-hover': tailwindTheme.colors.gray[500],
    'btn-text': tailwindTheme.colors.gray[50],
    'btn-reversed': tailwindTheme.colors.slate[200], //'#EEE',
    'btn-reversed-hover': tailwindTheme.colors.slate[300],
    'btn-reversed-text': tailwindTheme.colors.zinc[600], //'#555',
    'btn-reversed-icon': '#333',
    'btn-primary-bg': '#1a8927',
    'btn-primary-bg-hover': '#187723',
    'btn-primary-text': 'white',
    'btn-secondary-bg': '#187A90',
    'btn-secondary-bg-hover': '#166d81',
    'btn-secondary-text': 'white',
    'btn-danger-bg': '#FA3E3E',
    'btn-danger-bg-hover': '#aa090e',
    'btn-danger-text': 'white',
    'btn-go-to-dp': '#187A90',
    'content-bg': 'white',
    'content-border': 'none',
    'content-separator': '#DEE6E7',
    'content-bg-secondary': '#f7f7f7',
    'content-bg-tertiary': '#F5FAFF',
    'content-text': '#111',
    'mobile-nav-separator': '#555',
    accent: '#187a90',
    'module-background': 'white',
    'accent-secondary': '#555',
    'accent-tertiary': '#999',
    'accent-muted': '#DEE6E7',
    'accent-hover': '#166d81',
    'accent-bright': '#36BEDD',
    'accent-bright-hover': '#24b5d6',
    'standard-icon': '#555',
    'important-icon': '#187A90',
    'info-bg': '#187a90',
    'info-bg-success': '#1a8927',
    'info-bg-success-muted': '#DAEDDC',
    'info-bg-warning': '#d99f23',
    'info-text-warning': '#271D07',
    'info-danger': '#B3090F',
    'info-bg-danger': '#B3090F',
    'info-bg-danger-muted': '#e9dad9',
    'info-text-danger': '#ffffff',
    'info-bg-incomplete': '#DEE6E7',
    'info-text-incomplete': '#111',
    'info-bg-misc': '#555',
    'info-bg-misc-muted': '#dae4ef',
    'info-bg-warning-muted': '#f5eddf',
    'input-bg': '#fff',
    'input-border': '#ccc',
    'progress-expected-bg': '#DEE6E7',
    'progress-expected-text': '#111',
    'info-text': 'white',
    'info-text-alternative': '#989898',
    'calendar-accent': '#CF3237',
    'calendar-header-bg': '#383838',
    'calendar-header-btn-bg': '#555',
    'calendar-header-text': 'white',
    'calendar-body-bg': '#F5FAFF',
    'calendar-body-bg-subdued': '#DEE6E7',
    'calendar-body-text': '#111',
    'calendar-body-text-subdued': '#969292',
    'calendar-mini-month-bg': '#D4453B',
    'calendar-border': 'rgba(0,0,0,0.075)',
    'calendar-buttons-bg': '#949494',
    'calendar-border-color': 'transparent',
    'calendar-header-margin': '0 -10px',
    'calendar-margin-top': '0',
    'sup-panel-bg': '#212121',
    'sup-panel-bg-darker': '#1c1c1c',
    'sup-panel-bg-lighter': '#1c1c1c',
    'sup-panel-text': 'white',
    'sup-panel-outline-text': '#969292',
    'content-page-subheader': '#555',
    'current-segment-link': '#187A90',
    'icon-on-dark': '#FFFFFF',
    'icon-on-light': '#000000',
    'icon-standard-on-light': '#555',
    'icon-standard-on-dark': '#999',
    'chat-info-bg': '#867551',
    'icon-dark-reverse': '#FFF',
    'icon-accent-on-light': '#187a90',
    'icon-accent-on-dark': '#36BEDD',
    'input-placeholder': '#757575',
    'font-size-xx-small': '10px',
    'font-size-x-small': '11px',
    'font-size-small': '12px',
    'font-size-medium': '14px',
    'font-size-x-medium': '16px',
    'font-size-large': '18px',
    'font-size-x-large': '20px',
    'font-size-xx-large': '22px',
    'font-size-xxx-large': '28px',
    'font-families': '"Open Sans", sans-serif',
    execonlineLogo: 'white',
    'platform-width': 'calc(100vw - 360px)',
    'platform-max-width': '1260px',
    'platform-team-max-width': '1110px',
    'title-text': 'white',
    'container-padding': '20px',
  },
};

const defaultLightTheme: DefaultTheme = {
  variables: {
    ...defaultTheme.variables,
    'btn-bg': '#999999',
    'btn-text': 'white',
    'btn-reversed': '#f7f7f7',
    'btn-reversed-hover': '#EDF0F5',
    'btn-reversed-icon': '#fff',
    'btn-primary-text': 'white',
    'btn-secondary-bg': '#187a90',
    'btn-secondary-bg-hover': '#187a90',
    'btn-secondary-text': 'white',
    'btn-danger-bg': '#FA3E3E',
    'btn-danger-bg-hover': '#d13939',
    'btn-danger-text': 'white',
    accent: '#187a90',
    'accent-hover': '#3b5a99',
    'content-border': '#DDD',
    'content-separator': '#eee',
    'mobile-nav-separator': '#666',
    'standard-icon': '#555',
    'important-icon': '#FA3E3E',
    'info-bg': '#187a90',
    'info-text': 'white',
    'calendar-header-bg': 'white',
    'calendar-header-text': '#333333',
    'calendar-body-bg': 'white',
    'calendar-buttons-bg': '#EDF0F5',
    'calendar-margin-top': '20px',
    'calendar-header-margin': '0',
    'sup-panel-bg': '#EDF0F5',
    'sup-panel-bg-darker': 'rgba(0, 0, 0, 0.1',
    'sup-panel-bg-lighter': 'rgba(0, 0, 0, 0.2)',
    'sup-panel-outline-text': '#8e99b1',
    'sup-panel-text': 'black',
    execonlineLogo: '#000',
    'body-bg': '#EDF0F5',
    'body-bg-darker': '#DCDEE3',
    'body-text': '#333333',
    'calendar-body-text': 'black',
    'title-text': 'black',
    'module-background': '#DEE6E7',
    'container-padding': '0 20px 20px 20px',
    'platform-width': 'calc(100vw - 360px)',
    'platform-max-width': '1260px',
  },
};

export const getDefaultTheme = (): DefaultTheme => {
  return defaultLightTheme;
};

//Facebook;
const facebook: CompanyTheme = {
  orgId: 1447,
  variables: {
    ...defaultTheme.variables,
    'body-bg': '#EDF0F5',
    'body-bg-darker': '#DCDEE3',
    'body-text': 'white',
    'static-page-bg-image': 'none',
    'btn-bg': '#999999',
    'btn-text': 'white',
    'btn-reversed': '#888',
    'btn-reversed-hover': '#666',
    'btn-reversed-icon': '#fff',
    'btn-primary-text': 'white',
    'btn-secondary-bg': '#187a90',
    'btn-secondary-bg-hover': '#187a90',
    'btn-secondary-text': 'white',
    'btn-danger-bg': '#FA3E3E',
    'btn-danger-bg-hover': '#d13939',
    'btn-danger-text': 'white',
    accent: '#187a90',
    'accent-hover': '#3b5a99',
    'content-border': '#DDD',
    'content-separator': '#eee',
    'mobile-nav-separator': '#666',
    'standard-icon': '#555',
    'important-icon': '#FA3E3E',
    'info-bg': '#187a90',
    'info-text': 'white',
    'calendar-header-bg': '#dfe2e8',
    'calendar-header-text': '#333333',
    'sup-panel-bg': '#294272',
    'sup-panel-bg-darker': '#22355b',
    'sup-panel-bg-lighter': '#3e598f',
    'sup-panel-outline-text': '#8e99b1',
    execonlineLogo: '#000',
  },
};

export const companyThemes = [facebook];

export const getTheme = (organizationId: Maybe<number>): Theme =>
  organizationId
    .andThen((id) => find((theme) => theme.orgId === id, companyThemes))
    .map<Theme>(identity)
    .getOrElseValue(getDefaultTheme());

export const setTheme = (theme: Theme): Task<DynamicImportError | ThemeApplicationError, Theme> =>
  importCssVarsPonyfillModule
    .mapError<DynamicImportError | ThemeApplicationError>(identity)
    .andThen(
      (cssVarsModule) =>
        new Task<ThemeApplicationError, Theme>((reject, resolve) => {
          try {
            cssVarsModule.default({ variables: theme.variables });
            resolve(theme);
          } catch (error) {
            reject({ kind: 'theme-application-error', error });
          }
          return noop;
        }),
    );

import { explicitMaybe, mergeObjectDecoders, stringLiteral } from '@execonline-inc/decoders';
import { alreadyTranslatedText } from '@execonline-inc/translations';
import Decoder, { field, oneOf, succeed } from 'jsonous';
import { resourceDecoder } from '../../Resource/Decoders';
import { baseResourceDecoder, documentPartsDecoder } from '../../ResourceStore/Decoders';
import { DocumentResource, Linkable, LinkableKind, LinkableResource } from './Types';

const documentPartsResourceDecoder: Decoder<DocumentResource> = resourceDecoder(
  mergeObjectDecoders(baseResourceDecoder, documentPartsDecoder),
);

export const linkableKindDecoder: Decoder<LinkableKind> = oneOf([
  stringLiteral<LinkableKind>('resource'),
  stringLiteral<LinkableKind>('discovery-portal-wizard'),
]);

export const linkableDecoder: Decoder<Linkable> = succeed({})
  .assign('label', field('label', alreadyTranslatedText))
  .assign('kind', field('kind', linkableKindDecoder))
  .assign('resource', field('resource', explicitMaybe(documentPartsResourceDecoder)));

export const linkableResourceDecoder: Decoder<LinkableResource> = resourceDecoder(linkableDecoder);

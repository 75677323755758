import { emptyFragment } from '@execonline-inc/execonline-ui';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { programRoute } from '../../ClientRoutes';
import { Program } from '../../ProgramStore/Types';
import { AlreadyTranslated, T } from '../../Translations';
import ArrowRightIcon from '../Cutesies/ArrowRightIcon';
import Linkable from '../Linkable';
import * as style from './style.module.css';

interface Props {
  programDetail: Program;
}

const CompletedProgramOverviewHeader: React.FC<Props> = ({ programDetail }) => {
  return (
    <div className={style.header}>
      <Link to={programRoute(programDetail.id)} data-test-program-overview-link={true}>
        <h3 className={style.title} data-test-program-title={programDetail.title}>
          <AlreadyTranslated content={programDetail.title} />
        </h3>
        <button className={style.overviewButton}>
          <T kind="Overview" />
          <div className={style.buttonIcon}>
            <ArrowRightIcon />
          </div>
        </button>
      </Link>
      {programDetail.linkables
        .map((l) => l.first)
        .map((linkable) => <Linkable linkable={linkable} />)
        .getOrElse(emptyFragment)}
    </div>
  );
};

export default CompletedProgramOverviewHeader;

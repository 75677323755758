import { emptyFragment, FileIcon } from '@execonline-inc/execonline-ui';
import { observer } from 'mobx-react';
import { AlreadyTranslated } from '../../Translations';
import DownloadViewModal from '../DownloadViewModal';
import { useOpenable } from '../Openable';
import { Typography } from '../UI';
import * as style from './style.module.css';
import { LinkableResource } from './Types';

interface Props {
  linkable: LinkableResource;
}

function Linkable({ linkable }: Props) {
  const { openableState, open, close } = useOpenable();

  switch (linkable.payload.kind) {
    case 'resource':
      return linkable.payload.resource
        .map((resource) => (
          <div>
            <button className={style.downloadButton} onClick={open}>
              <div className={style.buttonIcon}>
                <FileIcon size="sm" color="inherit" />
              </div>
              <Typography variant="caption" color="inherit">
                <AlreadyTranslated content={linkable.payload.label} />
              </Typography>
            </button>
            <DownloadViewModal resource={resource} close={close} modalState={openableState} />
          </div>
        ))
        .getOrElse(emptyFragment);
    case 'discovery-portal-wizard':
      return (
        <span>
          <AlreadyTranslated content={linkable.payload.label} />
        </span>
      );
  }
}

export default observer(Linkable);

import { ButtonProps } from '@execonline-inc/execonline-ui';
import { observer } from 'mobx-react';
import { ExperienceActions, LearnMoreAction } from '../../../Common/Experience/ExperienceActions';
import { ExperienceResource } from '../../../Common/Experience/Types';
import { useExperienceEnrollmentContext } from '../../../Common/ProgramExperienceEnrollment';
import EnrollmentStore from '../../Enrollment/Store';
import { useExperiencesContext } from '../../ExperienceSelection/Experiences/ExperiencesContext';

interface Props {
  invitationUuid: string;
  enrollmentStore: EnrollmentStore;
  experienceResource: ExperienceResource;
}

const buttonProps: ButtonProps = {
  className: 'w-auto min-w-36',
  fullWidth: false,
};

function PromotableExperienceTileFooter({ invitationUuid, experienceResource }: Props) {
  const { payload: experience } = experienceResource;
  const experiencesStore = useExperiencesContext();
  const { onOpen } = useExperienceEnrollmentContext();

  return (
    <div className="flex flex-row items-center gap-4 pt-6">
      <ExperienceActions
        invitationUuid={invitationUuid}
        experience={experience}
        experiencesStore={experiencesStore}
        buttonProps={buttonProps}
        onEnroll={onOpen}
      />
      <LearnMoreAction
        buttonProps={buttonProps}
        invitationUuid={invitationUuid}
        experience={experience}
      />
    </div>
  );
}

export default observer(PromotableExperienceTileFooter);

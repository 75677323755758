import { ButtonProps } from '@execonline-inc/execonline-ui';
import { observer } from 'mobx-react';
import { ExperienceActions } from '../../Experience/ExperienceActions';
import { useExperiencePopoverContext } from '../ExperiencePopoverContext';

interface Props {
  buttonProps?: ButtonProps;
}

function ExperienceDetailsActions({ buttonProps }: Props) {
  const {
    experienceResource: { payload: experience },
    invitationUuid,
    experiencesStore,
    onEnroll,
  } = useExperiencePopoverContext();

  return (
    <ExperienceActions
      invitationUuid={invitationUuid}
      experience={experience}
      experiencesStore={experiencesStore}
      buttonProps={buttonProps}
      onEnroll={onEnroll}
    />
  );
}

export default observer(ExperienceDetailsActions);
